.sidebar-title {
    height: 64px;
    color: #fff;
    background-color: #0b2d59;
}

.ant-layout .ant-layout-header {
    background-color: #fff;
}

.layout-header {
    padding-left: 20px;
    font-size: 24px;
}