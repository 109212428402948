@import 'antd-edit';
@import 'layout';

.flex-center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.flex-start {
    display: flex;
    justify-content: flex-start;
}

.flex-start-center {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.flex-between {
    display: flex;
    justify-content: space-between;
}

.flex-between-center {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.text-ellipsis {
    text-overflow:ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

// class= "p-r-1 p-t-1 p-b-1 p-l-1"
// 生成4个方向padding 0-30px
@for $i from 0 through 30 {
    .p-t-#{$i} {
        padding-top: $i + px !important;
    }
    .p-b-#{$i} {
        padding-bottom: $i + px !important;
    }
    .p-l-#{$i} {
        padding-left: $i + px !important;
    }
    .p-r-#{$i} {
        padding-right: $i + px !important
    }
}

// class= "m-r-1 m-t-1 m-b-1 m-l-1"
// 生成4个方向margin 0-60px
@for $i from 0 through 80 {
    .m-t-#{$i} {
        margin-top: $i + px !important;
    }
    .m-b-#{$i} {
        margin-bottom: $i + px !important
    }
    .m-l-#{$i} {
        margin-left: $i + px !important;
    }
    .m-r-#{$i} {
        margin-right: $i + px !important;
    }
}
// class = "b-r-5"
// 生成border-radius
@for $i from 0 through 20 {
    .b-r-#{$i} {
        border-radius: $i + px !important;
    }
}

// 生成宽度(以10px为跨度) 50-500px
@for $i from 5 through 50 {
    .w#{$i*10} {
        width: $i * 10px !important;
    }
}

// class = "b-r-5"
// 生成font-size
@for $i from 12 through 30 {
    .f#{$i} {
        font-size: $i + px !important;
    }
}
