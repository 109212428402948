.tutor-list-wrapper {
    .filter-item-group {
        .filter-item {
            margin-right: 10px;
            margin-bottom: 10px;
        }
    }
    .unseen-row {
        background: #b4eeee;
        .ant-table-cell-fix-left {
            background: #b4eeee;
        }
    }
}
