.tutor-edit-wrapper {
    .form-content {
        background-color: #fff;
        padding: 20px;
    }
    .ant-form-item-control {
        width: 100%;
    }
    .grade-list {

    }
}

.tutor-edit-sub-wrapper {
    background-color: #fff;
    padding: 20px;
}

.signed-table {
    .selected {
        background: #66ccff;
    }
}