#root {
    .ant-layout-content {
        height: calc(100% - 106px);
        padding: 20px 20px 0 20px;
        overflow-y: auto;
    }
    .table-row {
        cursor: pointer;
        .table-hover-icon {
            display: none;
        }
        &.hover .table-hover-icon {
            display: inline-block;
        }
    }
}

.table-row {
    cursor: pointer;
    .table-hover-icon {
        display: none;
    }
    &.hover .table-hover-icon {
        display: inline-block;
    }
}
